<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <SVGIcon
                hex-color="#0f88ef"
                name="earth"
                class="kt-svg-icon mt-1 mr-3"
            />
            <div class="kt-form__group--inline">
                <select
                    v-model="schoolYear"
                    class="form-control kt-font-bolder"
                >
                    <option
                        v-for="y in schoolYears"
                        :key="y.value"
                        :value="y.value"
                    >
                        {{ y.text }}
                    </option>
                </select>
            </div>
            <span class="kt-font-bold ml-3">
                Biliteracy Dashboard
            </span>

            <!-- <small class="ml-3">{{ lastUpdated }}</small> -->
        </template>
        <template #left-buttons>
            <!-- what -->
        </template>
        <template #buttons>
            <a
                href="#"
                title="Show Previous Year"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="nextYear"
            >
                <SVGIcon name="leftNavigationDoubleArrowLeft" />
            </a>
            <a
                href="#"
                title="Show Next Year"
                class="btn kt-subheader__btn-primary btn-icon ml-3"
                @click.stop.prevent="previousYear"
            >
                <SVGIcon name="leftNavigationDoubleArrowRight" />
            </a>
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <BiliteracySealStatistics />

        <div class="row">
            <div class="col-lg-6">
                <BiliteracySealBigFive :on-click="goToBreakdown" />
            </div>
            <div class="col-lg-6">
                <BiliteracySealByEthnicity :on-click="goToBreakdown" />
            </div>
            <div class="col-lg-6">
                <BiliteracySealByStatus :on-click="goToBreakdown" />
            </div>
            <div class="col-lg-6">
                <BiliteracySealByLanguage :on-click="goToBreakdown" />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import BiliteracySealStatistics from '../components/BiliteracySealStatistics.vue';
import BiliteracySealByLanguage from '../components/BiliteracySealByLanguage.vue';
import BiliteracySealByStatus from '../components/BiliteracySealByStatus.vue';
import BiliteracySealByEthnicity from '../components/BiliteracySealByEthnicity.vue';
import BiliteracySealBigFive from '../components/BiliteracySealBigFive.vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import Types from '../store/Types';

export default Vue.extend({
    name: 'BiliteracySealDashboardHome',
    components: {
        GeneralSubHeader,
        BiliteracySealBigFive,
        BiliteracySealByEthnicity,
        BiliteracySealByStatus,
        BiliteracySealByLanguage,
        BiliteracySealStatistics,
    },
    data() {
        return {
            loading: false,
            show: false,
            showYearPicker: false,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        title() {
            return this.$route.meta.title;
        },
        schoolYear: {
            set(schoolYear) {
                this.$store.commit(Types.mutations.SET_BILITERACY_SEAL_SCHOOL_YEAR, schoolYear);
            },
            get() {
                return this.$store.state.biliteracySeal.schoolYear;
            },
        },
        schoolYears() {
            return [{
                value: 2021,
                text: '2021 - 2022',
            }, {
                value: 2020,
                text: '2020 - 2021',
            }, {
                value: 2019,
                text: '2019 - 2020',
            }, {
                value: 2018,
                text: '2018 - 2019',
            }, {
                value: 2017,
                text: '2017 - 2018',
            }, {
                value: 2016,
                text: '2016 - 2017',
            }, {
                value: 2015,
                text: '2015 - 2016',
            }];
        },
    },
    methods: {
        previousYear() {
            const index = this.schoolYears.findIndex((y) => y.value === this.schoolYear);
            if (index > 0) {
                this.schoolYear = this.schoolYears[index - 1].value;
            }
        },
        nextYear() {
            const index = this.schoolYears.findIndex((y) => y.value === this.schoolYear);
            if (index < this.schoolYears.length - 1) {
                this.schoolYear = this.schoolYears[index + 1].value;
            }
        },
        goToBreakdown(filter) {
            if (!['BigFive', 'ByEthnicity', 'ByStatus', 'ByLanguage'].includes(filter)) return;
            this.$router.push({
                name: 'BiliteracySealBreakdown',
                params: { filter },
            });
        },
    },
});
</script>

<style lang="scss">

</style>
